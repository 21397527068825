<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      系统日志
    </el-card>
  </div>
</template>

<script>
export default {
  name: "SystemLog"
}
</script>

<style scoped>

</style>
